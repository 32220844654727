import React from 'react';
import { Box, Divider, Group } from '@mantine/core';
import AnalysisSamplingSection from './AnalysisSamplingSection';
import SamplePlanDetailsSection from './SamplePlanDetailsSection';
import SamplingSection from './SamplingSection';

const AnalysisSamplingStep = () => {
  return (
    <Box maw={800} mx="auto">
      <AnalysisSamplingSection />
      <Divider my="xl" />
      <Group gap="xl" align="flex-start">
        <SamplingSection />
        <SamplePlanDetailsSection />
      </Group>
    </Box>
  );
};

export default AnalysisSamplingStep;
