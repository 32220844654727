import { Flex } from '@mantine/core';
import React, { useRef } from 'react';
import useFieldGeometry from 'util/hooks/useFieldGeometry';
import ZoneAnalysisV3Map from '../map/ZoneAnalysisV3Map';
import ZoneAnalysisV3Container from '../ZoneAnalysisV3Container';

const SamplingZonesStep = () => {
  const drawRef = useRef<any>(null);
  const fieldGeometry = useFieldGeometry();

  return (
    <Flex h="100%" w="100%" gap="xl">
      <ZoneAnalysisV3Container drawRef={drawRef} fieldGeometry={fieldGeometry} />
      <ZoneAnalysisV3Map drawRef={drawRef} field={fieldGeometry} />
    </Flex>
  );
};

export default SamplingZonesStep;
