import React, { useRef, useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { Box, Button } from '@mantine/core';
import turfBbox from '@turf/bbox';
import booleanContains from '@turf/boolean-contains';
import booleanIntersects from '@turf/boolean-intersects';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import center from '@turf/center';
import difference from '@turf/difference';
import {
  Feature,
  featureCollection,
  FeatureCollection,
  MultiPolygon,
  Point,
  point,
  Polygon,
} from '@turf/helpers';
import union from '@turf/union';
import showToast, { type ToastType } from 'actions/toastActions';
import useMapboxGl from 'common/MapHooks';
import { type ViewPortProps } from 'common/Maps/types';
import {
  CIRCLE,
  DELETE,
  FIELD_BOUNDARY,
  FIELD_OUTLINE,
  FILL,
  GROUPED_ZONES,
  GROUP_MAX_OPACITY,
  HIGHLIGHT_POINT,
  HIGHLIGHT_ZONE,
  LINE,
  MERGE,
  MODES,
  PARTIAL_ANALYTICS,
  PARTIAL_CIRCLE,
  PARTIAL_ZONE,
  POINT,
  POINT_STYLING,
  POINT_STYLING_IDS,
  POLYGON,
  SPLIT,
  STATIC_OUTLINE,
  WHITE_OUTLINE,
  ZONE_SELECTED,
  ZONE_TYPES,
} from 'constants/mapbox';
import { US_MIDWEST_VIEWPORT } from 'constants/mapViewport';
import { CUSTOM_POINTS, GRID_POINTS, SPLIT_DENSITY } from 'constants/samplePlanning';
import mapboxgl, {
  EventData,
  GeoJSONSource,
  GeoJSONSourceRaw,
  Layer,
  LngLatBoundsLike,
  MapboxGeoJSONFeature,
  MapMouseEvent,
} from 'mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { FieldType } from 'store/fields/types';
import { updateZoneAnalysis } from 'store/zoneAnalysisV2/actions';
import { ZoneAnalysisStateType } from 'store/zoneAnalysisV2/reducer';
import { ANALYSIS_TYPES, ZoneAnalysisKeyType } from 'store/zoneAnalysisV2/types';
import { getString } from 'strings/translation';
import {
  clipZonesToLayer,
  convertToCircle,
  getNewZonesFromDrag,
  getRotatedGridZones,
  processSingleZoneFeature,
  processZoneFeatureCollection,
  sortFeatures,
  splitMultipolygon,
  splitZone,
} from 'util/geospatial';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { removeMapLayer } from 'util/mapbox';
import { BORDER_GREY, OVERLAY_GREY, WHITE } from 'util/mapImageryColors';
import { getMapSelectedUuids, isGridsOption, isPointsOption } from 'util/samplePlan';
import { v4 as uuid } from 'uuid';
import DrawingTools from './DrawingToolsV3';

interface MapProps {
  field: FieldType;
  drawRef: { current: any | null };
}

type MouseRefType = (
  ev: MapMouseEvent & {
    features?: MapboxGeoJSONFeature[] | undefined;
  } & EventData,
) => void;

const ZoneAnalysisV3Map = ({ field, drawRef }: MapProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const mouseDownRef = useRef<MouseRefType>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);

  const analysis: ZoneAnalysisStateType['plan'] = useSelector(
    (state: RootState) => state.zoneAnalysisV2.plan,
  );

  const [prevZones, setPrevZones] = useState(analysis.previewZones);
  const [drawAction, setDrawAction] = useState<string | null>(null);
  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];
  const [currentLayerIds, setGroupLayerIds] = useState<string[]>([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [initialViewport, setInitialViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });
  const [mapCreationOption, setMapCreationOption] = useState(analysis.creationOption);
  const [proMapCreationOption, setProMapCreationOption] = useState(analysis.proPointCreationOption);
  const [mapDensity, setMapDensity] = useState<number>(analysis.density);
  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
    width: 0,
    height: 0,
  });
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const [mode, setMode] = useState(MODES.SELECT);
  const isBioZoneCreationMode = analysis.zoneCreationType === ANALYSIS_TYPES.CREATION_OPTION;

  const updatePlanState = useCallback(
    (metaKeyValue: ZoneAnalysisKeyType) => dispatch(updateZoneAnalysis(metaKeyValue)),
    [],
  );

  const displayToast = (message: string, type?: ToastType, time?: number) =>
    showToast(message, type, time);

  useMapboxGl(mapContainerRef, mapRef, drawRef, viewport, setViewport, () => {}, true);

  useEffect(() => {
    if (mapRef.current && mapHasLoaded && viewport.width && viewport.height) {
      const bbox = turfBbox(field) as LngLatBoundsLike;
      mapRef.current.fitBounds(bbox, { duration: 0, padding: 30 });
      const zoom = mapRef.current.getZoom();
      setInitialViewport((prevViewport) => ({
        ...prevViewport,
        zoom,
      }));
    }
  }, [mapRef, mapHasLoaded, viewport.width, viewport.height, field]);

  useEffect(() => {
    mapRef.current?.on('load', () => {
      const bbox = turfBbox(field) as LngLatBoundsLike;
      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;
      if (!mapRef.current?.getLayer(FIELD_BOUNDARY)) {
        mapRef.current?.addLayer({
          id: FIELD_BOUNDARY,
          type: FILL,
          source,
          paint: { 'fill-color': WHITE, 'fill-opacity': 0.4 },
        });
      }
      if (!mapRef.current?.getLayer(FIELD_OUTLINE)) {
        mapRef.current?.addLayer({
          id: FIELD_OUTLINE,
          type: LINE,
          source,
          paint: WHITE_OUTLINE,
        });
      }
      // Move point styling to render above the field layer
      POINT_STYLING_IDS.forEach((id) => mapRef.current?.moveLayer(id));
      mapRef.current?.fitBounds(bbox, { duration: 0, padding: 30 });

      const zoom = mapRef.current?.getZoom();
      const viewportSetter = (prevViewport: ViewPortProps) => ({
        ...prevViewport,
        latitude: centerLatitude,
        longitude: centerLongitude,
        zoom: zoom || US_MIDWEST_VIEWPORT.zoom,
      });
      // with the TS definition as necessary
      setViewport(viewportSetter);
      setInitialViewport(viewportSetter);
    });
  }, [centerLatitude, centerLongitude, field, mapRef]);

  useEffect(() => {
    if (!analysis.zones) {
      drawRef.current?.deleteAll();
    }
  }, [analysis.zones, drawRef]);

  const updateSize = useCallback(() => {
    const width = wrapperRef.current?.clientWidth || 0;
    const height = window.innerHeight - (wrapperRef.current?.offsetTop || 0);
    setViewport((prevViewport) => ({
      ...prevViewport,
      width,
      height,
    }));
  }, [wrapperRef]);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [wrapperRef, updateSize]);

  useEffect(() => {
    if (mapRef.current && !mapHasLoaded) {
      mapRef.current.on('load', () => {
        setMapHasLoaded(true);
        setMode(MODES.SELECT);
      });
    }
  }, [mapRef, mapHasLoaded]);

  useEffect(() => {
    if (mapHasLoaded && analysis.zones) {
      drawRef.current?.deleteAll();
      drawRef.current?.add(analysis.zones);
    }
  }, [mapHasLoaded, drawRef, analysis.zones, analysis.zoneGeomType]);

  useEffect(() => {
    if (!analysis.zones) {
      drawRef.current?.deleteAll();
    }
  }, [analysis.zones, drawRef]);

  const clipNewZoneToMap = useCallback(
    (newFeature: Feature<Polygon>) => {
      const newUuid = newFeature.properties?.sample_uuid;
      const all: FeatureCollection<Polygon> = drawRef.current.getAll();
      const withUuids = all.features.filter(
        (feat) => feat.properties?.sample_uuid,
      ) as Feature<Polygon>[];
      // Preserve location of newFeature if it already exists
      const alreadyInFeatures = withUuids.some((feat) => feat.properties?.sample_uuid === newUuid);
      const allZoneFeatures = alreadyInFeatures ? withUuids : [...withUuids, newFeature];
      // Deletes contained zones, clips overlapping zones, splits multipolygons into polygons
      const overlappingZones = allZoneFeatures
        .map((existingFeat) => {
          if (
            existingFeat.properties?.sample_uuid !== newUuid &&
            booleanIntersects(existingFeat, newFeature)
          ) {
            if (booleanContains(newFeature, existingFeat)) {
              return [];
            }
            const diffBetween = difference(existingFeat, newFeature);
            if (diffBetween) {
              return splitMultipolygon(diffBetween) as Feature<Polygon>[];
            }
          }
          return [existingFeat];
        })
        .flat();
      // Push new zones without uuids to the end, preserving original order
      const sortedZones = sortFeatures(overlappingZones);
      const clippedZones = clipZonesToLayer(featureCollection(sortedZones), field);
      // Redo zone numbers in case a zone was removed
      // Add any new uuids to all packages, including uuid of newFeature unless updating an existing zone
      const processedZones = clippedZones.features.map((feat, index) => {
        const processed = processSingleZoneFeature(
          feat,
          index + 1,
          ZONE_TYPES.POLYGON,
        ) as Feature<Polygon>;
        return processed;
      }) as Feature<Polygon>[];
      updatePlanState({
        zones: featureCollection(processedZones),
      });
    },
    [drawRef],
  );

  const createPolygon = useCallback(
    (e: FeatureCollection<Polygon>) => {
      try {
        const feat = e.features[0];
        if (!booleanIntersects(feat, field.features[0])) {
          drawRef.current.delete(feat.id);
        } else {
          const newFeat = {
            ...feat,
            properties: {
              sample_uuid: uuid(),
            },
          } as Feature<Polygon>;
          clipNewZoneToMap(newFeat);
        }
      } catch (err) {
        displayToast(getString('errorWithFieldGeometryMsg', language), 'error', 7000);
      }
    },
    [drawRef, clipNewZoneToMap],
  );

  const moveGrid = useCallback(
    (e) => {
      if (analysis.previewZones) {
        const newPreviewZoneFeatCollection = getNewZonesFromDrag(
          [e.lngLat.lng, e.lngLat.lat],
          analysis.previewZones,
        );
        if (newPreviewZoneFeatCollection) {
          const preview = mapRef.current?.getLayer('preview-boundary');

          // Set layer data directly
          const previewBoundary: mapboxgl.GeoJSONSource = mapRef.current?.getSource(
            'preview-boundary',
          ) as GeoJSONSource;
          const previewOutline: mapboxgl.GeoJSONSource = mapRef.current?.getSource(
            'preview-outline',
          ) as GeoJSONSource;
          previewBoundary.setData(newPreviewZoneFeatCollection);

          // Do not show for points
          if (preview?.type !== CIRCLE) {
            previewOutline.setData(newPreviewZoneFeatCollection);
          }
        }
      }
    },
    [mapRef, analysis.previewZones],
  );

  const onMouseUp = useCallback(
    (e) => {
      if (analysis.previewZones) {
        const newPreviewZoneFeatCollection = getNewZonesFromDrag(
          [e.lngLat.lng, e.lngLat.lat],
          analysis.previewZones,
        );
        if (newPreviewZoneFeatCollection && mouseDownRef.current) {
          updatePlanState({
            previewZones: newPreviewZoneFeatCollection,
          });
          mapRef.current?.off('mousemove', moveGrid);
          mapRef.current?.off('mousedown', 'preview-boundary', mouseDownRef.current);
          setIsMouseDown(false);
        }
      }
    },
    // Circular dep issue, need to be able to shut off listener here
    [mapRef, moveGrid, analysis.previewZones, mouseDownRef],
  );

  const mouseDownHelper: any = useCallback(
    (e: any) => {
      e.preventDefault();
      mapRef.current?.on('mousemove', moveGrid);
      mapRef.current?.once('mouseup', onMouseUp);
    },
    [mapRef, moveGrid, onMouseUp],
  );

  const createPoint = useCallback(
    (e: FeatureCollection<Point>) => {
      try {
        const all: FeatureCollection<Point> = drawRef.current.getAll();
        const clean = all.features.filter((feat) => feat.properties?.sample_uuid);
        if (booleanPointInPolygon(e.features[0], field.features[0])) {
          const newFeat = processSingleZoneFeature(
            e.features[0],
            clean.length + 1,
            ZONE_TYPES.POINT,
          );
          updatePlanState({
            zones: featureCollection([...clean, newFeat]) as FeatureCollection<Point>,
          });
        }
      } catch (err) {
        displayToast(getString('errorWithFieldGeometryMsg', language), 'error', 7000);
      }
    },
    [drawRef],
  );

  const updatePolygon = useCallback(
    (e: FeatureCollection<Polygon>) => {
      const feat = e.features[0];
      clipNewZoneToMap(feat);
    },
    [clipNewZoneToMap],
  );

  useEffect(() => {
    // Grids, turn off listener and turn back on when config changes
    if (
      analysis.density !== mapDensity ||
      analysis.creationOption !== mapCreationOption ||
      analysis.proPointCreationOption !== proMapCreationOption ||
      analysis.previewZones?.features !== prevZones?.features
    ) {
      if (mouseDownRef.current) {
        mapRef.current?.off('mousedown', 'preview-boundary', mouseDownRef.current);
      }
      setMapDensity(analysis.density);
      setMapCreationOption(analysis.creationOption);
      setProMapCreationOption(analysis.proPointCreationOption);
      setIsMouseDown(false);
    }
  }, [
    mapRef,
    analysis.previewZones,
    analysis.density,
    mapDensity,
    mouseDownRef,
    moveGrid,
    analysis.creationOption,
    mapCreationOption,
    proMapCreationOption,
    analysis.proPointCreationOption,
    prevZones,
  ]);

  const getZoneCreationType = useCallback(() => {
    return isBioZoneCreationMode ? analysis.creationOption : analysis.proPointCreationOption;
  }, [isBioZoneCreationMode, analysis.creationOption, analysis.proPointCreationOption]);

  useEffect(() => {
    if (mapHasLoaded && mapRef.current) {
      if (isGridsOption(getZoneCreationType()) && !isMouseDown) {
        setIsMouseDown(true);
        setPrevZones(analysis.previewZones);
        mapRef.current?.on('mousedown', 'preview-boundary', mouseDownHelper);
        // @ts-ignore
        mouseDownRef.current = mouseDownHelper;
      } else if (isPointsOption(getZoneCreationType())) {
        mapRef.current?.on('draw.create', createPoint);
        mapRef.current?.off('draw.create', createPolygon);
        mapRef.current?.off('draw.update', updatePolygon);
      } else {
        mapRef.current?.off('draw.create', createPoint);
        mapRef.current?.on('draw.create', createPolygon);
        mapRef.current?.on('draw.update', updatePolygon);
      }
    }
  }, [
    mapRef,
    mapHasLoaded,
    analysis.zoneGeomType,
    createPoint,
    createPolygon,
    updatePolygon,
    mouseDownHelper,
    analysis.previewZones,
    isMouseDown,
    prevZones,
  ]);

  const resetStaticLine = useCallback(() => {
    if (analysis.analysisMode === SPLIT_DENSITY) {
      if (mapRef.current?.getLayer(STATIC_OUTLINE)) {
        mapRef.current?.setPaintProperty(STATIC_OUTLINE, 'line-opacity', 0.1);
      }
    } else {
      if (mapRef.current?.getLayer(STATIC_OUTLINE)) {
        mapRef.current?.setPaintProperty(STATIC_OUTLINE, 'line-opacity', 1);
      }
    }
  }, [mapRef, analysis.analysisMode]);

  const removeGroupLayers = useCallback(() => {
    setGroupLayerIds((currentLayers) => {
      currentLayers.forEach((layer) => {
        removeMapLayer(mapRef, layer);
        removeMapLayer(mapRef, `${layer}-outline`);
      });
      return [];
    });
  }, [mapRef]);

  const setToDefaultMode = useCallback(
    (modeStr: string) => {
      setMode(modeStr);
      drawRef.current?.changeMode(modeStr);
    },
    [drawRef],
  );

  const clickUnlockedHelper = useCallback(
    (e: any, geomType: typeof POLYGON | typeof POINT) => {
      const all: FeatureCollection<Polygon | Point> = drawRef.current.getAll();
      const foundZone = all.features.find((feat) => {
        const convertedPolygon =
          feat.geometry.type === POINT
            ? convertToCircle(feat as Feature<Point>)
            : (feat as Feature<Polygon>);
        const foundPointOrPoly = booleanPointInPolygon(
          point([e.lngLat.lng, e.lngLat.lat]),
          convertedPolygon,
        );
        return (
          foundPointOrPoly &&
          convertedPolygon.properties?.zone_type === geomType.toLocaleLowerCase()
        );
      });
      const zoneUuid = foundZone?.properties?.sample_uuid;
      if (foundZone && zoneUuid) {
        if (mapRef.current?.getLayer(ZONE_SELECTED)) {
          const { _data } = mapRef.current?.getSource(ZONE_SELECTED) as any;
          const alreadyHighlighted = (_data as FeatureCollection).features.some(
            (feat) => feat.properties?.sample_uuid === zoneUuid,
          );
          const zoneAddedOrRemoved = alreadyHighlighted
            ? (_data as FeatureCollection).features.filter(
                (feat) => feat.properties?.sample_uuid !== zoneUuid,
              )
            : [_data.features, foundZone].flat();
          const highlightLayer = featureCollection(
            zoneAddedOrRemoved as Feature<Polygon | Point>[],
          );
          (mapRef.current?.getSource(ZONE_SELECTED) as GeoJSONSource).setData(highlightLayer);
        } else {
          const source = {
            type: 'geojson',
            data: featureCollection([foundZone]),
          } as GeoJSONSourceRaw;
          mapRef.current?.addLayer({
            id: ZONE_SELECTED,
            type: geomType === POINT ? CIRCLE : FILL,
            source,
            // @ts-ignore
            paint: geomType === POINT ? HIGHLIGHT_POINT : HIGHLIGHT_ZONE,
          });
        }
      }
    },
    [drawRef, mapRef, analysis.zoneGeomType],
  );

  const onClickUnlockedZone = useCallback(
    (e: any) => clickUnlockedHelper(e, POLYGON),
    [clickUnlockedHelper],
  );

  const onClickUnlockedPoint = useCallback(
    (e: any) => clickUnlockedHelper(e, POINT),
    [clickUnlockedHelper],
  );

  const resetDrawingMode = useCallback(
    (locking: boolean) => {
      if (!locking) {
        removeMapLayer(mapRef, PARTIAL_ANALYTICS);
        removeGroupLayers();
        resetStaticLine();
      }
      setDrawAction(null);
      setToDefaultMode(
        // eslint-disable-next-line no-nested-ternary
        locking
          ? MODES.STATIC
          : [CUSTOM_POINTS, GRID_POINTS].includes(getZoneCreationType())
            ? MODES.DRAW_POINT
            : MODES.SELECT,
      );
      // turn all listeners off
      [onClickUnlockedZone, onClickUnlockedPoint].forEach((handler) =>
        mapRef.current?.off('click', handler),
      );
    },
    [
      mapRef,
      removeGroupLayers,
      resetStaticLine,
      setToDefaultMode,
      getZoneCreationType,
      onClickUnlockedZone,
      onClickUnlockedPoint,
    ],
  );

  const handleMerge = () => {
    const all: FeatureCollection<Polygon> = drawRef.current.getAll();
    const selectedUuids = getMapSelectedUuids(mapRef);
    const selectedZones = all.features.filter(
      (single) =>
        single.properties?.sample_uuid && selectedUuids.includes(single.properties.sample_uuid),
    );
    if (selectedZones.length > 1) {
      try {
        const selectedMerge = union(...selectedZones);
        const mergeZones = splitMultipolygon(selectedMerge as Feature<MultiPolygon | Polygon>);
        // Only merge if it results in a single polygon
        if (mergeZones.length === 1) {
          const filteredZones = all.features.filter(
            (feat) =>
              feat.properties?.sample_uuid && !selectedUuids.includes(feat.properties.sample_uuid),
          );
          const mUuid = uuid();
          const mergeZoneWithUuid = {
            ...mergeZones[0],
            properties: {
              sample_uuid: mUuid,
            },
          } as Feature<Polygon>;
          // Redo zone numbers to account for deleted zones
          const processedZones = processZoneFeatureCollection(
            featureCollection([...filteredZones, mergeZoneWithUuid]),
            POLYGON,
          );
          drawRef.current?.deleteAll();

          updatePlanState({
            zones: processedZones as FeatureCollection<Polygon>,
          });
          removeMapLayer(mapRef, ZONE_SELECTED);
          resetDrawingMode(false);
          displayToast(getString('mergeZonesSuccess', language));
        } else {
          displayToast(getString('invalidZoneComboError', language), 'error');
        }
      } catch (e) {
        displayToast(getString('couldNotMergeSelectedError', language), 'error');
      }
    } else {
      displayToast(getString('selectZonesToMerge', language));
    }
  };

  const handleSplit = async () => {
    const all: FeatureCollection<Polygon> = drawRef.current.getAll();
    const selectedUuids = getMapSelectedUuids(mapRef);
    const selectedZones = all.features.filter(
      (single) =>
        single.properties?.sample_uuid && selectedUuids.includes(single.properties.sample_uuid),
    );

    if (selectedZones.length) {
      const splitCollections = await Promise.allSettled(
        selectedZones.map((zone) => splitZone(zone)),
      );
      if (splitCollections.some((promise) => promise.status === 'fulfilled')) {
        // Only remove uuids of zones that were successfully split
        const removeUuids: string[] = [];
        const newZones = selectedZones.reduce((list, zone, index) => {
          const splitPromise = splitCollections[index];

          if (splitPromise.status === 'fulfilled') {
            const splitZoner = splitPromise.value as FeatureCollection<Polygon>;

            const processedSplit = splitZoner.features.map((feature) =>
              processSingleZoneFeature(feature, 0, ZONE_TYPES.POLYGON),
            );

            const oldUuid = zone.properties?.sample_uuid;
            if (oldUuid) {
              removeUuids.push(oldUuid);
            }

            return list.concat(processedSplit as Feature<Polygon>[]);
          }

          return list;
        }, [] as Feature<Polygon>[][]);
        const filteredZones = all.features.filter(
          (feat) =>
            feat.properties?.sample_uuid && !removeUuids.includes(feat.properties.sample_uuid),
        );
        // Redo zone numbers to account for deleted zones
        const processedZones = processZoneFeatureCollection(
          featureCollection([...filteredZones, ...newZones.flat()]),
          POLYGON,
        );

        drawRef.current?.deleteAll();
        removeMapLayer(mapRef, ZONE_SELECTED);
        updatePlanState({
          zones: processedZones as FeatureCollection<Polygon>,
        });
        resetDrawingMode(false);
        if (removeUuids.length === selectedUuids.length) {
          displayToast(getString('splitZonesSuccess', language));
        } else {
          displayToast(getString('splitEveryZoneError', language), 'error');
        }
      } else {
        displayToast(getString('splitEveryZoneError', language), 'error');
      }
    } else {
      displayToast(getString('selectZonesToMerge', language));
    }
  };

  const handleDelete = () => {
    const deletingZones = getMapSelectedUuids(mapRef);
    const filteredZones = analysis.zones?.features.filter(
      (feat) => !deletingZones.includes(feat.properties?.sample_uuid),
    );
    removeMapLayer(mapRef, ZONE_SELECTED);
    resetDrawingMode(false);
    updatePlanState({
      zones: featureCollection(filteredZones as Feature<Polygon>[]),
    });
    displayToast(getString('deleteZonesSuccess', language));
  };

  const handleSelect = (action: string, start: boolean) => {
    if ([MERGE, SPLIT, DELETE].includes(action)) {
      setDrawAction(action);
    } else {
      setDrawAction(null);
    }
    if (start) {
      drawRef.current?.changeMode(MODES.STATIC);
      if (analysis.zoneGeomType === POINT) {
        mapRef.current?.on('click', onClickUnlockedPoint);
      } else {
        mapRef.current?.on('click', onClickUnlockedZone);
      }
    } else {
      resetDrawingMode(false);
    }
  };

  useEffect(() => {
    if (analysis.disableMapTools) {
      resetDrawingMode(true);
    } else {
      resetDrawingMode(false);
    }
  }, [
    analysis.analysisMode,
    analysis.disableMapTools,
    analysis.zoneGeomType,
    mapRef,
    resetDrawingMode,
    analysis.creationOption,
    analysis.proPointCreationOption,
  ]);

  const setLayer = useCallback(
    (id: string, source: GeoJSONSourceRaw, layer: Partial<Layer>) => {
      if (mapRef.current) {
        if (mapRef.current.getLayer(id) && mapRef.current.getLayer(id).type !== layer.type) {
          removeMapLayer(mapRef, id);
        }
        if (!mapRef.current.getLayer(id) && !mapRef.current.getSource(id) && layer.type) {
          mapRef.current.addLayer({
            id,
            // @ts-ignore
            type: layer.type,
            source,
            // @ts-ignore
            paint: layer.paint,
          });
        } else if (source.data) {
          // @ts-ignore
          (mapRef.current.getSource(id) as GeoJSONSource).setData(source.data);
        }
      }
    },
    [mapRef],
  );

  useEffect(() => {
    if (mapHasLoaded && mapRef) {
      if (analysis.previewZones) {
        removeMapLayer(mapRef, 'preview-boundary');
        removeMapLayer(mapRef, 'preview-outline');
        const source = {
          type: 'geojson',
          data: analysis.previewZones,
        } as GeoJSONSourceRaw;
        if (analysis.zoneGeomType === POINT) {
          setLayer('preview-boundary', source, {
            type: CIRCLE,
            paint: POINT_STYLING,
          });
          removeMapLayer(mapRef, 'preview-outline');
        } else {
          setLayer('preview-boundary', source, {
            type: FILL,
            paint: { 'fill-color': OVERLAY_GREY, 'fill-opacity': 0.4 },
          });
          setLayer('preview-outline', source, {
            type: LINE,
            paint: { 'line-color': BORDER_GREY, 'line-width': 2 },
          });
        }
      } else {
        removeMapLayer(mapRef, 'preview-boundary');
        removeMapLayer(mapRef, 'preview-outline');
      }
    }
  }, [setLayer, mapRef, mapHasLoaded, analysis.previewZones, analysis.zoneGeomType]);

  const handleGroupedZones = useCallback(() => {
    const groupLayers =
      analysis.zones?.features.reduce(
        (groups, feature) => {
          const groupId = feature.properties?.sample_group;
          if (groupId) {
            const layerName = `${GROUPED_ZONES}-${groupId}`;
            if (!groups[layerName]) {
              groups[layerName] = [feature];
            } else {
              groups[layerName].push(feature);
            }
          }
          return groups;
        },
        {} as { [name: string]: Feature<Polygon | Point>[] },
      ) || {};
    // Clear existing group layers
    currentLayerIds.forEach((layer) => {
      removeMapLayer(mapRef, layer);
      removeMapLayer(mapRef, `${layer}-outline`);
    });
    setGroupLayerIds([]);
    const layerNames = Object.keys(groupLayers);
    if (layerNames.length) {
      setGroupLayerIds((currentLayers) => currentLayers.concat(layerNames));
      try {
        const opacityIncrement = GROUP_MAX_OPACITY / (layerNames.length + 1);
        layerNames.forEach((layer, index) => {
          const source = {
            type: 'geojson',
            data: featureCollection(groupLayers[layer]),
          } as GeoJSONSourceRaw;
          if (analysis.zoneGeomType === POLYGON) {
            try {
              const unionizedGroup = union(...(groupLayers[layer] as Feature<Polygon>[]));
              const groupZones = splitMultipolygon(
                unionizedGroup as Feature<MultiPolygon | Polygon>,
              );
              source.data = featureCollection(groupZones);
            } catch (error) {
              source.data = featureCollection(groupLayers[layer]);
            }
          }

          if (analysis.zoneGeomType === POINT) {
            setLayer(layer, source, {
              type: CIRCLE,
              paint: {
                ...PARTIAL_CIRCLE,
                'circle-opacity': opacityIncrement * (index + 1),
              },
            });
          } else {
            setLayer(layer, source, {
              type: FILL,
              paint: {
                ...PARTIAL_ZONE,
                'fill-opacity': opacityIncrement * (index + 1),
              },
            });
            setLayer(`${layer}-outline`, source, {
              type: LINE,
              paint: {
                ...WHITE_OUTLINE,
                'line-opacity': 0.5,
              },
            });
          }
        });
      } catch (e) {
        // log error to expose topology exceptions to the client console for debugging
        // eslint-disable-next-line
        console.error(e);
        displayToast(getString('unableToDisplayZoneGroupingError', language), 'error');
      }
    }
  }, [analysis.zones, analysis.zoneGeomType, setLayer, removeGroupLayers]);

  useEffect(() => {
    if (mapHasLoaded && mapRef && analysis.disableMapTools) {
      if (analysis.analysisMode === SPLIT_DENSITY) {
        removeMapLayer(mapRef, PARTIAL_ANALYTICS);
        handleGroupedZones();
        resetStaticLine();
      } else {
        removeGroupLayers();
        removeMapLayer(mapRef, PARTIAL_ANALYTICS);
        resetStaticLine();
      }
    }
  }, [
    mapRef,
    mapHasLoaded,
    handleGroupedZones,
    removeGroupLayers,
    resetStaticLine,
    analysis.analysisMode,
    analysis.disableMapTools,
  ]);

  const rotateGrid = useCallback(
    (isClockwise: boolean) => {
      if (analysis.previewZones) {
        const { rotationAngle, newPreviewZones } = getRotatedGridZones(
          isClockwise,
          analysis.previewZones,
        );
        updatePlanState({
          gridAngle: analysis.gridAngle + rotationAngle,
        });
        if (mouseDownRef.current) {
          mapRef.current?.off('mousedown', 'preview-boundary', mouseDownRef.current);
          newPreviewZones?.length &&
            updatePlanState({
              previewZones: featureCollection(newPreviewZones),
            });
          setIsMouseDown(false);
        }
      }
    },
    [analysis.previewZones, mapRef, mouseDownRef, analysis.gridAngle],
  );

  const drawModeSetter = (drawType: any) => () => {
    setMode(drawType);
    if (drawRef.current) {
      drawRef.current.changeMode(drawType);
    }
  };

  const recenterMap = () => {
    setViewport(initialViewport);
    if (mapRef.current) {
      mapRef.current.setZoom(initialViewport.zoom);
      mapRef.current.setCenter([initialViewport.longitude, initialViewport.latitude]);
    }
  };

  return (
    <Box h="100%" pos="relative" ref={wrapperRef} w="100%">
      <Box ref={mapContainerRef} h="100%" w="100%" />
      <Button variant="white" onClick={recenterMap} bottom={10} pos="absolute" right="3rem">
        {getString('recenter', language)}
      </Button>
      <DrawingTools
        drawModeSetter={drawModeSetter}
        mode={mode}
        drawAction={drawAction}
        handleMerge={handleMerge}
        handleSplit={handleSplit}
        handleDelete={handleDelete}
        handleSelect={handleSelect}
        rotateGrid={rotateGrid}
      />
    </Box>
  );
};
export default ZoneAnalysisV3Map;
