import React from 'react';
import { Select, Stack, Text, Textarea } from '@mantine/core';
import { OperationType } from 'store/operation/types';
import { ZoneAnalysisStateType } from 'store/zoneAnalysisV2/reducer';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import useSampleTiming from 'util/hooks/useSampleTiming';
import { getSamplerOptions, getScannerOptions } from 'util/samplePlan';

type Props = {
  operation?: OperationType;
  analysis?: ZoneAnalysisStateType['plan'];
  isProScanOrTillRx?: boolean;
};

const SamplingSection = ({ operation, analysis, isProScanOrTillRx }: Props) => {
  const language = useBroswerLanguage();
  const samplerOptions = operation ? getSamplerOptions(operation) : [];
  const scannerOptions = operation ? getScannerOptions(operation) : [];
  const { timings } = useSampleTiming();

  const updatePlanState = (value: unknown) => {
    // eslint-disable-next-line no-console
    console.log(value);
  };

  return (
    <Stack maw={300} flex={1}>
      <Text fz="h4" fw="bold" component="h2">
        {getString('sampling', language)}
      </Text>
      {analysis?.products.length ? (
        <>
          {operation?.billing_user_id ? (
            <Select
              data={samplerOptions}
              label={getString('sampledBy', language)}
              value={samplerOptions[analysis.samplerIndex]?.value}
              onChange={(value) =>
                value &&
                updatePlanState({
                  samplerIndex: samplerOptions?.findIndex((sam) => sam.value === value),
                })
              }
            />
          ) : (
            <Text c="darkRed">**{getString('addBillingResponsibility', language)}**</Text>
          )}
        </>
      ) : null}
      {/* TODO: rm analysis check */}
      {isProScanOrTillRx && analysis && (
        <Select
          data={scannerOptions}
          label={getString('scannedBy', language)}
          value={scannerOptions[analysis.scannerIndex]?.value}
          onChange={(value) => {
            value &&
              updatePlanState({
                scannerIndex: scannerOptions?.findIndex((sam) => sam.value === value),
              });
          }}
        />
      )}
      <Select
        required
        label={getString('sampleTiming', language)}
        // TODO: rm analysis check
        value={analysis ? timings[analysis.sampleTimingIndex]?.value : undefined}
        data={timings.map((timing) => ({
          value: timing.value,
          label: timing.displayName,
        }))}
        onChange={(val) =>
          val &&
          updatePlanState({
            sampleTimingIndex: timings.findIndex((timing) => timing.value === val),
          })
        }
      />
      <Textarea
        label={getString('samplerInstructions', language)}
        minRows={3}
        value={analysis?.notes}
        onChange={(e) => updatePlanState({ notes: e.target.value })}
      />
    </Stack>
  );
};

export default SamplingSection;
