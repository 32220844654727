import React from 'react';
import { Divider, Space, Title } from '@mantine/core';
import { getString } from 'strings/translation';
import useFieldGeometry from 'util/hooks/useFieldGeometry';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { roundTwoDecimal } from 'util/numUtils';
import { getAcreageUnitFromLang } from 'util/units';

const ZoneAnalysisV3Header = () => {
  const language = useBroswerLanguage();
  const fieldGeometry = useFieldGeometry(); // TODO: react-query
  const { name, acreage } = fieldGeometry.features[0].properties;
  const headerTitleName = `${name} (${roundTwoDecimal(acreage)} ${getAcreageUnitFromLang(language)})`;
  const headerTitle = `${headerTitleName} - ${getString('editSamplePlan', language)}`;

  return (
    <div>
      <Space h="md" />
      <Title order={2}>{headerTitle}</Title>
      <Divider my="md" size="sm" />
    </div>
  );
};

export default ZoneAnalysisV3Header;
