import React, { useState } from 'react';
import { Button, Group, Stepper } from '@mantine/core';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import AnalysisSamplingStep from './AnalysisSamplingStep';
import ReviewOrderStep from './ReviewOrderStep';
import SamplingZonesStep from './SamplingZonesStep';

const getNavButtonsText = (): Record<number, { previous: string | null; next: string }> => ({
  0: {
    previous: null,
    next: 'Set sampling zones',
  },
  1: {
    previous: 'Analysis & sampling',
    next: 'Review order',
  },
  2: {
    previous: 'Sampling zones',
    next: 'Confirm order',
  },
});

const SampleOrderSteps = () => {
  const [activeStepNumber, setActiveStepNumber] = useState(0);
  const navButtonsConfig = getNavButtonsText()[activeStepNumber];
  const { previous, next } = navButtonsConfig;
  const isFinalStep = activeStepNumber === Object.keys(getNavButtonsText()).length - 1;

  const handleStepChange = (nextStep: number) => {
    setActiveStepNumber(nextStep);
  };

  return (
    <>
      <Stepper
        active={activeStepNumber}
        onStepClick={handleStepChange}
        mx="auto"
        flex={1}
        display="flex"
        w="100%"
        styles={{
          content: {
            flex: 1,
            overflowY: 'auto',
            margin: '0 auto',
            width: '100%',
          },
          root: {
            flexDirection: 'column',
            overflowY: 'hidden',
            gap: 'var(--mantine-spacing-md)',
          },
        }}
      >
        <Stepper.Step allowStepSelect label="Analysis & Sampling">
          <AnalysisSamplingStep />
        </Stepper.Step>
        <Stepper.Step allowStepSelect label="Sampling Zones">
          <SamplingZonesStep />
        </Stepper.Step>
        <Stepper.Step
          allowStepSelect // TODO: disable if other steps are not completed
          label="Confirm Order"
        >
          <ReviewOrderStep />
        </Stepper.Step>
      </Stepper>
      <Group
        bg="var(--mantine-color-body)"
        justify="center"
        py="md"
        style={{
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: 'var(--mantine-color-gray-3)',
        }}
      >
        {!!previous && (
          <Button
            size="lg"
            leftSection={<FaArrowLeft width={32} style={{ padding: 0 }} />}
            variant="default"
            onClick={() => handleStepChange(activeStepNumber - 1)}
          >
            {previous}
          </Button>
        )}
        <Button
          size="lg"
          rightSection={isFinalStep ? null : <FaArrowRight width={32} style={{ padding: 0 }} />}
          onClick={() => handleStepChange(activeStepNumber + 1)}
        >
          {next}
        </Button>
      </Group>
    </>
  );
};

export default SampleOrderSteps;
