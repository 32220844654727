import React from 'react';
import useSampleTiming from 'util/hooks/useSampleTiming';
import { Loader, Center } from '@mantine/core';
import useFieldGeometry from 'util/hooks/useFieldGeometry';
import useOperation from 'util/hooks/useOperation';
import useOperationUsers from 'util/hooks/useOperationUsers';
import ZoneAnalysis from './ZoneAnalysis';

const ZoneAnalysisContainerV3 = () => {
  const fieldGeometry = useFieldGeometry(); // TODO: react-query
  const { operation } = useOperation(); // TODO: react-query
  useSampleTiming();

  const { operationUsers } = useOperationUsers();

  if (!operation || !fieldGeometry || !operationUsers) {
    return (
      <Center h="85vh">
        <Loader />
      </Center>
    );
  }

  return <ZoneAnalysis />;
};

export default ZoneAnalysisContainerV3;
