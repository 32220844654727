import React from 'react';
import { Stack, Flex, Select, Switch, Text, TextInput } from '@mantine/core';
import { AdminAccess } from 'common';
import { OperationType } from 'store/operation/types';
import { getString } from 'strings/translation';
import { agenciesAllowedForSamplePlan } from 'util/agency';
import useBroswerLanguage from 'util/hooks/useLanguage';

type Props = {
  operation?: OperationType;
};

const SamplePlanDetailsSection = ({ operation }: Props) => {
  const language = useBroswerLanguage();

  const agencyOptions = operation
    ? agenciesAllowedForSamplePlan(operation, [])?.map((agency) => ({
        id: agency.id,
        label: agency.name,
        value: String(agency.id),
      }))
    : [];

  return (
    <Stack maw={400} flex={1} mb="lg">
      <Text fz="h4" fw="bold" component="h2">
        {getString('samplePlanDetails', language)}
      </Text>
      <Flex
        columnGap="xl"
        rowGap="md"
        display="grid"
        align="center"
        style={{
          gridTemplateColumns: 'auto 1fr',
        }}
      >
        <Text>{getString('name', language)}:</Text>
        <TextInput aria-label={getString('name', language)} />
        <AdminAccess>
          <Text>RND:</Text>
          <Switch size="md" aria-label="RND" />
        </AdminAccess>
        {agencyOptions.length > 0 && (
          <>
            <Text>{getString('agency', language)}:</Text>
            <Select aria-label={getString('agency', language)} data={agencyOptions} w="14rem" />
          </>
        )}
      </Flex>
    </Stack>
  );
};

export default SamplePlanDetailsSection;
