import React from 'react';
import { Stack } from '@mantine/core';

import SampleOrderSteps from './Components/steps/SampleOrderSteps';
import ZoneAnalysisV3Header from './Components/ZoneAnalysisV3Header';

const ZoneAnalysis = () => {
  return (
    <Stack style={{ overflow: 'hidden' }} flex={1} gap={0} maw={1300} mx="auto" w="100%">
      <ZoneAnalysisV3Header />
      <SampleOrderSteps />
    </Stack>
  );
};

export default ZoneAnalysis;
